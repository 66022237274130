<template>
  <van-form
    :show-error-message="false"
    label-width="32vw"
    :class="[
      'van-form__text-color',
      'plan-info',
      showExecuteBtn
        ? 'plan-info__wrapper-height'
        : 'plan-info__wrapper-height__not'
    ]"
  >
    <van-field
      v-for="(item, idx) in fieldsConf"
      :key="idx"
      :value="planInfo[item.prop]"
      disabled
      autosize
      :type="item.type || 'text'"
      :label="item.label"
      input-align="right"
    />
    <van-field label="排查周期" :value="cycle" disabled input-align="right" />
    <van-field label="排查内容" :value="ic" disabled input-align="right" />
    <van-field
      v-if="planInfo.itemType === 0"
      label="按责任人生成任务"
      :value="genTask"
      disabled
      input-align="right"
    />
  </van-form>
</template>

<script>
import { fieldsConf } from "@/views/plans/constant";
import { getSelectCycleTypes } from "@/api/psm/plan";

export default {
  name: "PlanInfo",
  props: {
    planInfo: {
      type: Object,
      default: () => {}
    },
    showExecuteBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cycle() {
      const planInfo = this.planInfo;
      const dateTypeDic = this.dateTypeDic;
      return `${planInfo.cycleNum +
        dateTypeDic[planInfo.cycleId] +
        planInfo.frequency} 次`;
    },
    ic() {
      const mapping = { 0: "隐患排查", 1: "风险管控" };
      return mapping[this.planInfo.itemType];
    },
    genTask() {
      const mapping = { 0: "否", 1: "是" };
      return mapping[this.planInfo.personChargeFlag];
    }
  },
  data() {
    return {
      fieldsConf,
      dateTypeDic: {}
    };
  },
  created() {
    getSelectCycleTypes().then(x => {
      x?.forEach(t => (this.dateTypeDic[t.value] = t.label));
    });
  }
};
</script>

<style lang="scss">
.plan-info {
  overflow: auto;
  .van-cell {
    padding: 12px 16px;
  }
  .van-cell__title,
  .van-cell__value .van-field__body > *:not(textarea) {
    height: 30px;
    line-height: 30px;
  }
}
.plan-info__wrapper-height {
  height: calc(100vh - 58px - 90px - 44px);
}

.plan-info__wrapper-height__not {
  height: calc(100vh - 58px - 44px);
}
</style>
