<template>
  <div>
    <van-pull-refresh
      v-if="list.length"
      v-model="isPullRefreshing"
      class="plan-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="['plan-list__rows', idx === list.length - 1 && 'border-none']"
          :border="false"
          @click="gotoDetails(item)"
        >
          <p class="title">{{ item.planName || "--" }}</p>
          <div class="inspect-record-item">
            <div class="intro">
              <div class="field field-left text-overflow">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  color="#aeb3c0"
                  name="renyuan"
                />
                <span>{{ item.checkChargesName || "--" }}</span>
              </div>
              <div class="field field-right">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  color="#aeb3c0"
                  name="bumen"
                />
                <span>{{ item.departmentName || "--" }}</span>
              </div>
            </div>
            <div class="time">
              <div class="field field-center">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  color="#aeb3c0"
                  name="ico-timerange"
                />
                <span class="time-text text-overflow"
                  >{{ item.planCheckStartDate }}至{{
                    item.planCheckEndDate
                  }}</span
                >
              </div>
            </div>
            <div v-if="item.checkTime" class="time">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="chengnuoshijian"
              />
              <div class="field field-center">
                <span class="time-text text-overflow">
                  {{ item.checkTime }}
                </span>
              </div>
            </div>
            <div class="status" :style="{ color: statusColor(item.status) }">
              <van-icon
                :name="statusName(item.status)"
                class-prefix="iconfont"
                class="status-icon"
              />
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <box-empty v-else style="height: calc(100vh - 58px - 44px)"></box-empty>
  </div>
</template>

<script>
import BoxEmpty from "@/components/BoxEmpty";
import { getPageInspectRecordsZm } from "@/views/plans/enterprise-customization/zhongmei/api";

const ICON_STATUS = {
  0: {
    iconName: "daiwancheng",
    iconColor: "#DEA148"
  },
  1: {
    iconName: "yiwancheng",
    iconColor: "#8EB433"
  }
};

export default {
  name: "InspectRecord",
  components: { BoxEmpty },
  props: {
    planInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      queryInfo: {
        planName: "",
        planId: "",
        checkedDepartment: "",
        checkUserId: "",
        orgCode: "",
        department: "",
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  computed: {
    statusName() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return ICON_STATUS[status]["iconName"];
      };
    },
    statusColor() {
      return function(status) {
        status = String(status);
        if (status === "null" || !status) return "";
        return ICON_STATUS[status]["iconColor"];
      };
    }
  },
  methods: {
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.planId = this.planInfo.id;
        const { list, total } = await getPageInspectRecordsZm(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({
        name: "InspectEdit",
        params: { id, planId: this.planInfo.id }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.plan-list__wrapper {
  height: calc(100vh - 105px);
}
.plan-list__rows {
  .title {
    font-size: 16px;
    color: #000;
  }
  .inspect-record-item {
    position: relative;
  }
  .status {
    position: absolute;
    right: 0;
    top: 0;
    .status-icon {
      font-size: 50px;
    }
  }
}
</style>
